<template>
    <div class="hello">
        <div v-show="scene" id="map"></div>
    </div>
</template>

<script>
import { Scene ,PointLayer } from '@antv/l7';
import { GaodeMap } from '@antv/l7-maps';
import $bus from '../utils/bus.js'
export default {
    name: 'IsMap',
    props: {
        msg: String,
        cityInfo:{
            type:Array,
            default:function (){
                return [];
            }
        },
    },
    watch:{
        cityInfo(){
            this.city_info = this.cityInfo
            this.createMap()
        }
    },
    created() {
    },
    data(){
      return {
          scene:'',
          city_info:[]
      }
    },
    methods: {
        createMap(){
            this.scene = null
            this.scene = new Scene({
                id: 'map',
                enableMultiPassRenderer: true,
                map: new GaodeMap({
                    style: 'dark',
                    center: [ parseFloat(this.city_info.lng),parseFloat(this.city_info.lat) ],
                    pitch: 70,
                    zoom: 14
                })
            });
            this.scene.addImage(
                'marker',
                'https://zhibo001-10049604.cos.ap-shanghai.myqcloud.com/aaa.png'
            );
            this.mapDot();
            // 筛选地图信息
            $bus.on('screenMap',(data)=>{
                console.log(data)
            })
            // 更改定位区域信息
            $bus.on('mapCity',(data)=>{
                console.log(data)
            })
            // 地图视角调整
            $bus.on('changAngle',(data)=>{
                console.log(data)
                let pith = 0
                if (data==1){
                    pith = 80
                }
                this.scene.setPitch(pith);
            })
            // 定位茶馆
            $bus.on('teahousePosition',(data)=>{
                console.log(data)
                let ILngLat = [data.merchant_latitude, data.merchant_longitude];
                this.scene.panTo(ILngLat);
                this.scene.setZoom(20);
                this.scene.setPitch(80);

            })
        },
        mapDot(){
            if (this.city_info.length==0){
                return false;
            }
           this.$http.post('/mapManage/index/mapList',this.city_info)
                .then(data => {
                    console.log(data)
                    // 地图光标
                    const pointLayer = new PointLayer({ depth: false })
                        .source(data.data, {
                            parser: {
                                type: 'json',
                                x: 'merchant_longitude',
                                y: 'merchant_latitude'
                            }
                        })
                        .shape('cylinder')
                        .size('level', function(level) {
                            return [ 8,8, level * 2 + 100 ];
                        })
                        .animate(true)
                        .active(true)
                        .color('#006CFF')
                        .style({
                            opacity: 0.9,
                            opacityLinear: {
                                enable: true, // true - false
                                dir: 'up' // up - down
                            },
                            lightEnable: true
                        });
                    // pointLayer.on('mousemove', e => {
                    //     const popup = new Popup({
                    //         offsets: [ 0, 0 ],
                    //         closeButton: false
                    //     })
                    //         .setLnglat(e.lngLat)
                    //         .setHTML();
                    //     scene.addPopup(popup);
                    // });
                    // 名称
                    const teahouse = new PointLayer({})
                        .source(data.data, {
                            parser: {
                                type: 'json',
                                x: 'merchant_longitude',
                                y: 'merchant_latitude'
                            }
                        })
                        .shape('merchant_name', 'text')
                        .size(12)
                        .color('t', [ '#fff', '#fff', '#fff' ])
                        .style({
                            textAnchor: 'center', // 文本相对锚点的位置 center|left|right|top|bottom|top-left
                            textOffset: [ 0, -40 ], // 文本相对锚点的偏移量 [水平, 垂直]
                            spacing: 2, // 字符间距
                            padding: [ 1, 1 ], // 文本包围盒 padding [水平，垂直]，影响碰撞检测结果，避免相邻文本靠的太近
                            stroke: '#ffffff', // 描边颜色
                            strokeWidth: 0.3, // 描边宽度
                            strokeOpacity: 1.0
                        });
                    // 坐标光圈
                    const coordinate = new PointLayer({ zIndex: 2, blend: 'additive' })
                        .source(data.data, {
                            parser: {
                                type: 'json',
                                x: 'merchant_longitude',
                                y: 'merchant_latitude'
                            }
                        })
                        .animate(true)
                        .shape('circle')
                        .size(40)
                        .color('rgb(22, 119, 255)')
                        .style({
                            unit: 'meter',
                        });
                    // 背景
                    const background = new PointLayer({ zIndex: 2, blend: 'additive' })
                        .source(data.data, {
                            parser: {
                                type: 'json',
                                x: 'merchant_longitude',
                                y: 'merchant_latitude'
                            }
                        })
                        .animate(true)
                        .shape('marker')
                        .size(150)
                        .style({
                            unit: 'meter',
                            offsets:[0,0],
                        });
                    // 文字1
                    const info1 = new PointLayer({ zIndex: 2, blend: 'additive' })
                        .source(data.data, {
                            parser: {
                                type: 'json',
                                x: 'merchant_longitude',
                                y: 'merchant_latitude'
                            }
                        })
                        .shape('本周', 'text')
                        .size(12)
                        .color('t', [ '#fff', '#fff', '#fff' ])
                        .style({
                            textAnchor: 'left', // 文本相对锚点的位置 center|left|right|top|bottom|top-left
                            textOffset: [ 70, 170 ], // 文本相对锚点的偏移量 [水平, 垂直]
                            spacing: 2, // 字符间距
                            padding: [ 1, 1 ], // 文本包围盒 padding [水平，垂直]，影响碰撞检测结果，避免相邻文本靠的太近
                            strokeOpacity: 1.0,
                            halo:0,
                        });
                    // 文字2
                    const info2 = new PointLayer({ zIndex: 2, blend: 'additive' })
                        .source(data.data, {
                            parser: {
                                type: 'json',
                                x: 'merchant_longitude',
                                y: 'merchant_latitude'
                            }
                        })
                        .shape( '预约：     次', 'text')
                        .size(12)
                        .color('t', [ '#ffd200', '#ffd200', '#ffd200' ])
                        .style({
                            textAnchor: 'left', // 文本相对锚点的位置 center|left|right|top|bottom|top-left
                            textOffset: [ 130, 150 ], // 文本相对锚点的偏移量 [水平, 垂直]
                            spacing: 2, // 字符间距
                            padding: [ 1, 1 ], // 文本包围盒 padding [水平，垂直]，影响碰撞检测结果，避免相邻文本靠的太近
                            stroke: '#ffd200', // 描边颜色
                            strokeWidth: 0.3, // 描边宽度0
                            strokeOpacity: 1.0,
                            halo:0,
                        });
                    const info22 = new PointLayer({ zIndex: 2, blend: 'additive' })
                        .source(data.data, {
                            parser: {
                                type: 'json',
                                x: 'merchant_longitude',
                                y: 'merchant_latitude'
                            }
                        })
                        .shape( 'times', 'text')
                        .size(12)
                        .color('t', [ '#ffd200', '#ffd200', '#ffd200' ])
                        .style({
                            textAnchor: 'left', // 文本相对锚点的位置 center|left|right|top|bottom|top-left
                            textOffset: [ 220, 150 ], // 文本相对锚点的偏移量 [水平, 垂直]
                            spacing: 2, // 字符间距
                            padding: [ 1, 1 ], // 文本包围盒 padding [水平，垂直]，影响碰撞检测结果，避免相邻文本靠的太近
                            stroke: '#ffd200', // 描边颜色
                            strokeWidth: 0.3, // 描边宽度0
                            strokeOpacity: 1.0,
                            halo:0,
                        });
                    // 文字3
                    const info3 = new PointLayer({ zIndex: 2, blend: 'additive' })
                        .source(data.data, {
                            parser: {
                                type: 'json',
                                x: 'merchant_longitude',
                                y: 'merchant_latitude'
                            }
                        })
                        .shape('转化：     元', 'text')
                        .size(12)
                        .color('t', [ '#ff9800', '#ff9800', '#ff9800' ])
                        .style({
                            textAnchor: 'left', // 文本相对锚点的位置 center|left|right|top|bottom|top-left
                            textOffset: [ 130, 110 ], // 文本相对锚点的偏移量 [水平, 垂直]
                            spacing: 2, // 字符间距
                            padding: [ 1, 1 ], // 文本包围盒 padding [水平，垂直]，影响碰撞检测结果，避免相邻文本靠的太近
                            stroke: '#ff9800', // 描边颜色
                            strokeWidth: 0.3, // 描边宽度
                            strokeOpacity: 1.0,
                            halo:0,
                        });
                    const info33 = new PointLayer({ zIndex: 2, blend: 'additive' })
                        .source(data.data, {
                            parser: {
                                type: 'json',
                                x: 'merchant_longitude',
                                y: 'merchant_latitude'
                            }
                        })
                        .shape('goods_num', 'text')
                        .size(12)
                        .color('t', [ '#ff9800', '#ff9800', '#ff9800' ])
                        .style({
                            textAnchor: 'left', // 文本相对锚点的位置 center|left|right|top|bottom|top-left
                            textOffset: [ 220, 110 ], // 文本相对锚点的偏移量 [水平, 垂直]
                            spacing: 2, // 字符间距
                            padding: [ 1, 1 ], // 文本包围盒 padding [水平，垂直]，影响碰撞检测结果，避免相邻文本靠的太近
                            stroke: '#ff9800', // 描边颜色
                            strokeWidth: 0.3, // 描边宽度
                            strokeOpacity: 1.0,
                            halo:0,
                        });
                    // 文字11
                    const info4 = new PointLayer({ zIndex: 2, blend: 'additive' })
                        .source(data.data, {
                            parser: {
                                type: 'json',
                                x: 'merchant_longitude',
                                y: 'merchant_latitude'
                            }
                        })
                        .shape('本月', 'text')
                        .size(12)
                        .color('t', [ '#fff', '#fff', '#fff' ])
                        .style({
                            textAnchor: 'left', // 文本相对锚点的位置 center|left|right|top|bottom|top-left
                            textOffset: [ 70, 90 ], // 文本相对锚点的偏移量 [水平, 垂直]
                            spacing: 2, // 字符间距
                            padding: [ 1, 1 ], // 文本包围盒 padding [水平，垂直]，影响碰撞检测结果，避免相邻文本靠的太近
                            strokeOpacity: 1.0,
                            halo:0,
                        });
                    // 文字22
                    const info5 = new PointLayer({ zIndex: 2, blend: 'additive' })
                        .source(data.data, {
                            parser: {
                                type: 'json',
                                x: 'merchant_longitude',
                                y: 'merchant_latitude'
                            }
                        })
                        .shape('预约：8次', 'text')
                        .size(12)
                        .color('t', [ '#ffd200', '#ffd200', '#ffd200' ])
                        .style({
                            textAnchor: 'left', // 文本相对锚点的位置 center|left|right|top|bottom|top-left
                            textOffset: [ 130, 60 ], // 文本相对锚点的偏移量 [水平, 垂直]
                            spacing: 2, // 字符间距
                            padding: [ 1, 1 ], // 文本包围盒 padding [水平，垂直]，影响碰撞检测结果，避免相邻文本靠的太近
                            stroke: '#ffd200', // 描边颜色
                            strokeWidth: 0.3, // 描边宽度0
                            strokeOpacity: 1.0,
                            halo:0,
                        });
                    // 文字33
                    const info6 = new PointLayer({ zIndex: 2, blend: 'additive' })
                        .source(data.data, {
                            parser: {
                                type: 'json',
                                x: 'merchant_longitude',
                                y: 'merchant_latitude'
                            }
                        })
                        .shape('转化：102元', 'text')
                        .size(12)
                        .color('t', [ '#ff9800', '#ff9800', '#ff9800' ])
                        .style({
                            textAnchor: 'left', // 文本相对锚点的位置 center|left|right|top|bottom|top-left
                            textOffset: [ 130, 20 ], // 文本相对锚点的偏移量 [水平, 垂直]
                            spacing: 2, // 字符间距
                            padding: [ 1, 1 ], // 文本包围盒 padding [水平，垂直]，影响碰撞检测结果，避免相邻文本靠的太近
                            stroke: '#ff9800', // 描边颜色
                            strokeWidth: 0.3, // 描边宽度
                            strokeOpacity: 1.0,
                            halo:0,
                        });
                    background.on('click', e => {
                        // console.log(e.feature)
                        $bus.emit('mapClick',e.feature)
                    });
                    pointLayer.on('click', e => {
                        // console.log(e.feature)
                        $bus.emit('mapClick',e.feature)
                    });
                    // scene.on('click', (e) => console.log(e));
                    this.scene.addLayer(info6)
                    this.scene.addLayer(info5)
                    this.scene.addLayer(info4);
                    this.scene.addLayer(info3);
                    this.scene.addLayer(info33);
                    this.scene.addLayer(info2);
                    this.scene.addLayer(info22);
                    this.scene.addLayer(info1);
                    this.scene.addLayer(background);
                    this.scene.addLayer(coordinate);
                    this.scene.addLayer(teahouse);
                    this.scene.addLayer(pointLayer);
                });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

</style>
