// main.ts
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import HighchartsVue from 'highcharts-vue'
import App from './App.vue'
import axios from "axios";

const app = createApp(App)
app.use(HighchartsVue)
app.use(ElementPlus)
axios.defaults.baseURL = 'https://app.etearoom.com'
app.config.globalProperties.$http = axios
app.mount('#app')