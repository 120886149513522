<template>
    <div class="DataTop">
        <div class="light">
            <div class="middle"></div>
            <div class="outer1"></div>
            <div class="outer2"></div>
            <div class="outer3"></div>
        </div>
        <div class="DataTop_title txt_bg">空席城市会客厅数据可视化平台</div>
    </div>
</template>
<script>
export default {
    name: 'DataTop',
}
</script>
<style scoped>

.DataTop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 10%;
    display: flex;
    text-align: center;
    background-image: url("../../static/top.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 80;
    user-select: none;
}

.light{
    position: absolute;
    left: 0%;
    bottom: 80%;
    animation: flow 4s linear  0s infinite;
    //margin-left: -10px;

}
.light1{
    left: 0%;
    bottom: 80%;
    animation: flow 4s linear  0s infinite;
}
.light2{
    left: 100%;
    bottom: 80%;
    animation: flow2 4s linear  0s infinite;
}
@keyframes flow{
    25%{transform:rotate(5deg);left: 25%;bottom: 60%;}
    29%{transform:rotate(45deg);left: 28%;bottom: 42%;}
    33%{transform:rotate(0);left: 31%;bottom: 5%;}
    62%{transform:rotate(0);left: 69%;bottom: 5%;}
    66%{transform:rotate(-45deg);left: 72%;bottom: 42%;}
    70%{transform:rotate(-5deg);left: 76%;bottom: 60%;}
    100%{transform:rotate(-5deg);left: 100%;bottom: 80%;}
}
/*@keyframes flow1{
    25%{transform:rotate(5deg);left: 25%;bottom: 60%;}
    29%{transform:rotate(45deg);left: 28%;bottom: 42%;}
    33%{transform:rotate(0);left: 31%;bottom: 5%;}
    50%{transform:rotate(0);left: 50%;bottom: 5%;}
    67%{transform:rotate(0);left: 31%;bottom: 5%;}
    71%{transform:rotate(45deg);left: 28%;bottom: 42%;}
    75%{transform:rotate(5deg);left: 25%;bottom: 60%;}
    100%{transform:rotate(5deg);left: 0%;bottom: 80%;}
}
@keyframes flow2{
    25%{transform:rotate(-5deg);left: 76%;bottom: 60%;}
    29%{transform:rotate(-45deg);left: 72%;bottom: 42%;}
    33%{transform:rotate(0);left: 69%;bottom: 5%;}
    50%{transform:rotate(0);left: 50%;bottom: 5%;}
    62%{transform:rotate(0);left: 69%;bottom: 5%;}
    66%{transform:rotate(-45deg);left: 72%;bottom: 42%;}
    70%{transform:rotate(-5deg);left: 76%;bottom: 60%;}
    100%{transform:rotate(-5deg);left: 100%;bottom: 80%;}
}*/
.light .middle{
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 5px;
    border-radius: 50%;
    background-color: rgba(253, 255, 255);
    z-index: 999;
}
.outer1{
    position: absolute;
    left: -2px;
    top: -1px;
    width: 24px;
    height: 7px;
    border-radius: 50%;
    background-color: rgba(156, 255, 255, 0.7);
    z-index: 990;
}
.outer2{
    position: absolute;
    left: -4px;
    top: -2px;
    width: 28px;
    height: 9px;
    border-radius: 50%;
    background-color: rgba(154, 255, 255, 0.7);
    z-index: 980;
    //box-shadow: 0 0 10px rgba(156, 255, 255, 1), 0 0 30px rgba(156, 255, 255, 1);
}
.outer3{
    position: absolute;
    left: -6px;
    top: -3px;
    width: 32px;
    height: 11px;
    border-radius: 50%;
    background-color: #5aeeff;
    z-index: 980;
    box-shadow: 0 0 10px rgba(156, 255, 255, 1), 0 0 30px rgba(156, 255, 255, 1);
}
.DataTop_title{
    display: block;
    font-size: 50px;
    font-weight: 600;
    width: 100%;
    height: 60px;
    margin: 1% 0;
}
</style>