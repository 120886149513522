<template>
    <div v-if="loading==true" style="position: fixed;background-color: #1d80da;width: 100vw;height: 100vh;z-index: 999;align-items: center;display: flex;justify-items: center;justify-content: center">
                 <div style="width: 600px;height: 240px;border-radius: 8px;border: 1px solid #0c1e3b;background-color: #FFF;">

                     <div style="align-items: center;">
                         <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm"  class="demo-ruleForm">
                             <div style="display: flex;justify-items: center;justify-content: center;align-items: center;margin-top: 50px">

                                    <div style="width: 200px;">
                                        <el-input type="password" v-model="password" placeholder="请输入密码" autocomplete="off"></el-input>

                                    </div>

                             </div>
                             <div style="display: flex;justify-items: center;justify-content: center;align-items: center;margin-top: 50px">
                                 <el-button type="primary" @click="login" style="width: 200px">登录系统</el-button>
                             </div>
                         </el-form>

                     </div>

                 </div>
    </div>
    <div>
        <IsMap :cityInfo="cityInfo"/>
        <DataLeft :timeType="timeType" :options="cityList" @leftOne="leftOne" :storereTurn="storereTurn" :abnormalList="abnormalList"  :city="fromData.city" @selectCity="change"/>
        <DataRight :store_info="store_info" :cityData="citydata" />
        <DataTop/>
        <div class="left_bg"></div>
        <div class="right_bg"></div>
        <div class="filter">
            <div class="show_txt" @click="showDropdown = !showDropdown">规划布局</div>
            <div class="filter_dropdown" v-if="showDropdown">
                <div @click="layout(0)">默认</div>
                <div @click="layout(1)">预约次数</div>
                <div @click="layout(2)">转化金额</div>
                <div class="filter_bottom">
                    <div @click="changAngle(1)">平视角度</div>
                    <div @click="changAngle(2)">俯视角度</div>
                </div>
            </div>
        </div>
        <div class="mask" v-if="showPop" @click.stop="showPop=false"></div>
        <div class="popup-draw-active" v-if="showPop">
            <div class="m-4 popup_info">
                <div>
                    <h2>规划布局</h2>
                    <el-radio-group v-model="layoutInfo.date" class="ml-4">
                        <el-radio text-color="#fff" label="0" size="large">默认</el-radio>
                        <el-radio text-color="#fff" label="1" size="large">本周</el-radio>
                        <el-radio text-color="#fff" label="2" size="large">本月</el-radio>
                    </el-radio-group>
                    <div class="input">
                        <i>{{ layoutInfo.status == 1 ? '次数' : '金额' }}范围；</i>
                        <input type="number" min="0" v-model="layoutInfo.smNum">
                        <i>{{ layoutInfo.status == 1 ? '次' : '元' }}</i> -
                        <input type="number" min="0" v-model="layoutInfo.lgNum"><i>{{
                        layoutInfo.status == 1 ? '次' : '元'
                        }}</i>
                    </div>
                </div>
                <div class="pop_btn">
                    <el-button size="large" @click="showPop = false" type="info" class="btn">取消</el-button>
                    <el-button size="large" type="primary" class="btn" @click="submit">确定</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IsMap from './components/IsMap.vue'
import DataLeft from "@/components/DataLeft.vue";
import DataRight from "@/components/DataRight.vue";
import DataTop from "@/components/DataTop.vue";
import $bus from "@/utils/bus";

var storage = window.localStorage;
export default {
    name: 'App',
    components: {
        DataRight,
        DataLeft,
        IsMap,
        DataTop
    },
    created() {
        this.sys_id = storage.getItem('sysId')
        if (this.sys_id!=null){
            this.loading = false
            this.initWebsocket()
        }else{
            this.loading = true
        }
    },
    data() {
        return {
            abnormalList:[],
            citydata:[],
            store_info:[],
            password:'',
            loading: true,
            fromData: {
                city: [],
            },
            sys_id:'',
            cityList: [],
            websocket: null,
            showDropdown: false,
            showPop: false,
            layoutInfo: {
                status: 0,
                smNum: 0,
                lgNum: 0,
                date: '0',
            },
            cityInfo:[],
            storereTurn:[]
        }
    },
    methods: {
        change(e){
            this.getCity(e)
            storage.setItem('city',e)
        },
        leftOne(e){
            if (e=="空席使用率"){
                this.cityInfo.leftOne = 1
            }else{
                this.cityInfo.leftOne = 0
            }
            this.getStorereTurn()
        },
        timeType(e){
          this.cityInfo.leftOneTime  =e
        },
        getStore(){
            this.$http.post('/mapManage/index/getStoreInfo', this.cityInfo)
                .then(res => {
                    console.log(res.data,'茶室入驻数据')
                    this.store_info = res.data
                })
        },
        getStorereTurn(){
            this.$http.post('/mapManage/index/getStorereTurn', this.cityInfo)
                .then(res => {
                    this.storereTurn = res.data
                })
        },
        getCity(e){
           this.$http.post('/mapManage/index/regionInfo', {sys_id:this.sys_id,region_text:e})
               .then(res => {
                   this.cityInfo = res.data.data
                   this.getStore()
                   this.getCityData()
                   this.getAbnormalList()
                   this. getStorereTurn()
               })
        },
        getCityData(){
            this.$http.post('/mapManage/index/getCityData', this.cityInfo)
                .then(res => {
                    this.citydata = res.data
                })
        },
        getAbnormalList(){
            this.$http.post('/mapManage/index/getAbnormalList', this.cityInfo)
                .then(res => {
                    this.abnormalList = res.data
                })
        },
        login(){
            if (!this.password){
                this.$notify({
                    title: '警告',
                    message: '请输入密码',
                    type: 'warning'
                });
                return false;
            }
            this.$http.post('/mapManage/index/login', {sys_id:this.sys_id,password:this.password})
            .then(res => {
                if (res.data.code==200){
                    this.sys_id = res.data.data
                    storage.setItem('sysId',this.sys_id)
                    location.reload()
                }else{
                    this.$notify({
                        title: '警告',
                        message: '密码错误',
                        type: 'warning'
                    });
                }
            }).catch((res)=>{
                console.log(res)
            })
        },
        initWebsocket() {
            let _this = this
            let wss = new WebSocket('wss://app.etearoom.com/socket')
            wss.onopen = function (res) {
                console.log(res)
            }
            wss.onmessage = function (evt) {
                let message = JSON.parse(evt.data);
                console.log(message)
                switch (message.type) {
                    case 'ping':
                        message.type = 'pong'
                        wss.send(JSON.stringify(message))
                        break;
                    default:
                        break;
                }
                switch (message.event) {
                    case 'loginStatus':
                        message.event = 'winLogin'
                        message.sys_id = this.sys_id
                        wss.send(JSON.stringify(message))
                        _this.getInit()
                        break;
                    case 'message':
                        if (message.region_id==_this.cityInfo.cid){
                            console.log('全部重新加载')
                            switch (message.type) {
                                case 1:
                                    console.log('全部重新加载')
                                    location.reload()
                                    break;
                                default:
                                    break;
                            }
                        }
                        break;
                    default:
                        break;
                }
            }
        },

        //初始化地图数据
        getInit() {
            if (!window.localStorage) {
                alert("浏览器不支持localstorage");
                return false;
            }
            let city = storage.getItem('city')
            this.fromData.city = city
            this.$http.post('/mapManage/index/index', this.fromData)
                .then(res => {
                    var data = res.data.data
                    console.log('--初始化数据--', data.cityList)
                    this.cityList = data.cityList
                    if (!this.fromData.city){
                        console.log('--初始化数据--', data.cityList[0].children[0])
                        this.fromData.city = data.cityList[0].children[0].value
                        storage.getItem('city',this.fromData.city )
                    }
                    this.getCity(this.fromData.city )
                })
        },
        //左侧数据A加载
        getLeft(e) {
            console.log(e)
        },
        layout(e) {
            console.log(e)
            if (e == 0) {
                console.log('默认')
            } else {
                this.layoutInfo.status = e
                this.showPop = true
            }
        },
        submit() {
            console.log(this.layoutInfo)
            if (this.layoutInfo.lgNum < this.layoutInfo.smNum) {
                alert('请输入正确的范围')
            } else {
                let data = ''
                $bus.emit('screenMap', data)
            }
        },
        changAngle(e) {
            $bus.emit('changAngle', e)
        }
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

body {
    margin: 0 !important;
    overflow: hidden;
    box-sizing: border-box;
}

.filter {
    position: fixed;
    top: 12%;
    right: 22%;
    z-index: 99;
}

.filter .show_txt {
    width: 120px;
    padding: 5px;
    background-color: rgba(15, 106, 180, 0.74);
    color: #fff;
    font-size: 16px;
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #1f3f76;
    user-select: none;
}

.filter_dropdown {
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
    position: absolute;
    left: 0;
    top: 120%;
    background-color: rgba(15, 106, 180, 1);
    color: #fff;
    overflow: hidden;
    border: 1px solid #1f3f76;
    user-select: none;
}

.filter_dropdown div {
    padding: 5%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.filter_dropdown .filter_bottom {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 0;
}

.input {
    padding: 5% 0;
}

.input i {
    font-style: normal;
    margin: 0 2%;
}

.input input {
    width: 20%;
}

.el-radio {
    color: #fff;
}

.el-radio__input.is-checked + .el-radio__label {
    color: #fff;
}

.left_bg {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 28%;
    background-image: linear-gradient(to right, #0c0b0c, rgba(12, 12, 12, 0.6) 70%, transparent 100%);
    z-index: 80;
    pointer-events: none;
}

.right_bg {
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: 28%;
    background-image: linear-gradient(to left, #0c0b0c, rgba(12, 12, 12, 0.6) 70%, transparent 100%);
    color: #fff;
    z-index: 80;
    pointer-events: none;
}

.txt_bg {
//color: #fff; background-image: -webkit-linear-gradient(top, #fff, #fff, #4289d4); -webkit-background-clip: text; -webkit-text-fill-color: transparent;
}

.title .title_top {
    position: relative;
    width: 100%;
    height: 2px;
    background-color: #142c4e;
}

.title .title_top:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 2px;
    width: 4%;
    background-color: #2e90ff;
}

.title .title_top:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 2px;
    width: 4%;
    background-color: #2e90ff;
}

.title .title_info .right_button {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 1px;
}

.title .title_info .right_button .text {
    color: #fff;
    padding-top: 12%;
    font-size: 14px;
}

.title .title_info .right_button button {
    border: none;
    border-top: 2px solid #7e8997;
    background-image: linear-gradient(to bottom, #343c49, #11192a);
    margin-left: 5px;
    height: 100%;
    color: #afbdc9;
}

.title .title_info .right_button .BtnActive {
    background-image: linear-gradient(to bottom, #1d4889, #11192a);
    color: #fff;
}

.title .title_info {
    position: relative;
    background-color: #0e1623;
    display: flex;
    align-items: center;
    padding: 5px 10px;
}

.title .title_info .txt {
    position: relative;
    margin-left: 10px;
    font-size: 18px;
    font-weight: 550;
//overflow: hidden; text-overflow: ellipsis; white-space: nowrap; user-select: none;
}

.title_bottom {
    width: 60%;
    height: 5px;
    background-image: linear-gradient(to right, rgba(13, 22, 38, 0.2) 41%, rgba(42, 194, 250, 0.5) 47%, #8dedff 50%, rgba(42, 194, 250, 0.5) 53%, rgba(13, 22, 38, 0.2) 59%, rgba(13, 22, 38, 0.2));
    background-size: 200%;
    animation: run 3s linear 0s infinite;
}

@keyframes run {
    50% {
        background-position: 100% 0px;
    }
    100% {
        background-position: 0% 0px;
    }
}

.title_bottom2 {
    width: 60%;
    height: 5px;
    background-image: linear-gradient(to right, rgba(13, 22, 38, 0.2) 41%, rgba(42, 194, 250, 0.5) 47%, #8dedff 50%, rgba(42, 194, 250, 0.5) 53%, rgba(13, 22, 38, 0.2) 59%, rgba(13, 22, 38, 0.2));
    background-size: 200%;
    animation: run 4s linear 0s infinite;
}

@keyframes run {
    50% {
        background-position: 100% 0px;
    }
    100% {
        background-position: 0% 0px;
    }
}

.title_bottom3 {
    width: 60%;
    height: 5px;
    background-image: linear-gradient(to right, rgba(13, 22, 38, 0.2) 41%, rgba(42, 194, 250, 0.5) 47%, #8dedff 50%, rgba(42, 194, 250, 0.5) 53%, rgba(13, 22, 38, 0.2) 59%, rgba(13, 22, 38, 0.2));
    background-size: 200%;
    animation: run 5s linear 0s infinite;
}

@keyframes run {
    50% {
        background-position: 100% 0px;
    }
    100% {
        background-position: 0% 0px;
    }
}

.tuglie1 {
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 12px;
}

.tuglie1 div:before {
    content: '';
    display: inline-block;
    margin: 0 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fff;
}

.tuglie1 div:nth-child(1):before {
    background-color: #3f75ff;
}

.tuglie1 div:nth-child(2):before {
    background-color: #fb9e42;
}

.tuglie1 div:nth-child(3):before {
    background-color: #dcdcdc;
}

.bottom {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bottom .img {
    width: 85%;
}

.bottom .img img {
    width: 100%;
    height: 100%;
}

.bottom .triangle {
    border: 2px solid #2e90ff;
    width: 8px;
    height: 8px;
}

.bottom .bottom_left {
    border-right: none;
    border-top: none;
}

.bottom .bottom_right {
    border-left: none;
    border-top: none;
}

.mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 990;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.popup-draw-active {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 999;
    width: 640px;
    height: 300px;
    padding: 30px;
    border-radius: 10px;
    border: 2px solid #2e90ff;
    background-color: rgba(15, 106, 180, 0.75);
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    transition: .3s;
    line-height: 1;
    text-align: center;
    color: #fff;
    background-repeat: round;
    background-size: 100%;
}

.popup_info {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.pop_btn {
    box-sizing: border-box;
    padding: 0 20%;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.popup_info .btn {
    width: 40%;
}

.popup_select {
    margin-top: 15%;
}

.loading {
    filter: blur(8px);
}
</style>
