<template>
  <div class="DataRight">
    <div class="right_content weather">
        <div class="weather_box">
            <div>
                <!-- <img src="" alt="天气">-->
            </div>
            <div v-for="item in weather" :key="item">
                {{item}}
            </div>
        </div>
    </div>
      <div class="store right_content">
          <div class="title">
              <div class="title_top"></div>
              <div class="title_info">
                  <img src="../../static/right.png">
                  <div class="txt txt_bg">茶室入驻数据</div>
              </div>
              <div class="title_bottom3"></div>
          </div>
          <div class="store_info">
              <div class="store_info_left">
                  <div class="store_city city"><p>{{storeInfo.city}}客厅城市数据</p></div>
                  <div><p>已覆盖{{storeInfo.districtNum}}个行政区</p></div>
                  <div><p>入驻门店{{storeInfo.storeNum}}家</p></div>
                  <div><p>空席数量{{storeInfo.myNum}}个</p></div>
                  <div><p>茶室数量{{storeInfo.teahouseNum}}个</p></div>
              </div>
              <div class="store_info_right">
                <div class="store_form">
                    <div class="chartSize" id="store_chart"></div>
                </div>
                  <div class="tuglie1">
                      <div>已入驻</div>
                      <div>未入驻</div>
                      <div>条件不符</div>
                  </div>
              </div>
          </div>
      </div>
      <div class="teaHouseTransform right_content">
          <div class="title">
              <div class="title_top"></div>
              <div class="title_info">
                  <img src="../../static/right.png">
                  <div class="txt txt_bg">转化数据（{{storeInfo.city}}）</div>
              </div>
              <div class="title_bottom2"></div>
          </div>
          <div class="teaHouseTransform_info">
              <div class="transform_chart">
                  <div class="chartSize" id="transform_chart"></div>
              </div>
          </div>
          <div class="bottom">
              <div class="triangle bottom_left"></div>
              <div class="img">
                  <img src="../../static/box_bottom.png">
              </div>
              <div class="triangle bottom_right"></div>
          </div>
      </div>
      <div class="city_data right_content">
          <div class="title">
              <div class="title_top"></div>
              <div class="title_info">
                  <img src="../../static/right.png">
                  <div class="txt txt_bg">会客厅城市数据</div>
              </div>
              <div class="title_bottom"></div>
          </div>
          <div class="city_data_info">
              <div class="store_city city">{{storeInfo.city}}客厅城市数据</div>
              <div class="table_box">
                  <table border="2" cellpadding="6" cellspacing="0"  width="100%" >
                      <thead>
                      <tr>
                          <td rowspan="2" colspan="1">区域</td>
                          <td colspan="2">空席茶室实时数据</td>
                          <td colspan="2">自营茶室实时数据</td>
                      </tr>
                      <tr>
                          <td>可预约</td>
                          <td>使用中</td>
                          <td>可预约</td>
                          <td>使用中</td>
                      </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item,index) in city_data" :key="index">
                            <td>{{ item.region }}</td>
                            <td>{{item.joinResidueNum}}</td>
                                <td>{{item.joinUsingNum}}</td>
                            <td>{{item.selfResidueNum}}</td>
                            <td>{{item.selfUsingNum}}</td>
                        </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      </div>
  </div>

</template>
<script>
import HighCharts from 'highcharts'
import axios from "axios";
import highcharts3d from 'highcharts/highcharts-3d'
import $bus from "@/utils/bus";

highcharts3d(HighCharts)
  export default {
      name: 'DataRight',
      props: {
          store_info:{
              type:Array,
              default:function (){
                  return [];
              }
          },
          cityData:{
              type:Array,
              default:function (){
                  return [];
              }
          },
      },
      watch:{
          store_info(){
              this.storeInfo = this.store_info
          },
          cityData(){
              this.city_data = this.cityData
          }
      },
      data(){
          return{
              weather:{
                  temperature :'多云0-5℃',
                  wind:'东南风2级',
                  date:'2023-05-12',
                  week:'周五',
                  time:'11:00'
              },
              weatherImg:'../../static/weather.png',
              storeInfo:{
                  city:'',
                  districtNum:5,
                  storeNum:105,
                  myNum:105,
                  teahouseNum:324,
              },
              city_data:[],
              storeChartOptions:{
                  title: {
                      text: ''
                  },
                  tooltip: {
                      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                  },
                  colors:['rgba(4, 68, 182,0.6)', 'rgba(251, 158, 66,0.6)', 'rgba(220, 220, 220,0.6)'],
                  chart: {
                      type: 'pie',
                      options3d: {
                          enabled: true,
                          alpha: 45,
                          beta: 0
                      },
                      backgroundColor: {
                          linearGradient: [0, 0, 500, 500],

                          stops: [
                              [0, 'rgb(255, 255, 255,0)'],
                              [0, 'rgb(200, 200, 255,0)']
                          ]
                      },
                  },
                  credits: {
                      enabled:false,                    // 默认值，如果想去掉版权信息，设置为false即可
                  },
                  plotOptions: {
                      pie: {
                          allowPointSelect: true,
                          cursor: 'pointer',
                          depth: 35,
                          dataLabels: {
                              enabled: true,
                              format: '{point.name}'
                          }
                      }
                  },
                  series: [{
                      type: 'pie',
                      name: '',
                      data: [
                          {
                              name: '49%',
                              y: 49,
                              sliced: true,
                              selected: true
                          },
                          ['15%',   15.0],
                          ['36%',   36.0]

                      ]
                  }]
              },
              transformOptions:{
                  colors:['rgba(29, 128, 218,1)', 'rgba(2, 244, 255,1)', 'rgba(227, 188, 45,1)','rgba(238, 97, 41,1)'],
                  chart: {
                      type: 'areaspline',
                      backgroundColor: {
                          linearGradient: [0, 0, 500, 500],

                          stops: [
                              [0, 'rgb(255, 255, 255,0)'],
                              [0, 'rgb(200, 200, 255,0)']
                          ]
                      },
                  },
                  title: {
                      text: ''
                  },

                  credits: {
                      enabled:false,                    // 默认值，如果想去掉版权信息，设置为false即可
                  },
                  legend: {
                      align: 'center',
                      x: 0,
                      verticalAlign: 'top',
                      y: -10,
                      itemStyle: {
                          color: '#ffffff',
                      },
                      itemHoverStyle:{
                          color: '#2da1fa',
                      },
                      shadow: false
                  },
                  xAxis: {
                      // allowDecimals: false,
                      labels: {
                          style: {
                              color: '#ffffff',
                              fontSize: "18px",
                              fontWeight: "blod",
                          },
                      },
                  },
                  yAxis: {
                      labels: {
                          // formatter: function () {
                          //     return this.value / 1000 + 'k';
                          // },
                          style: {
                              color: '#ffffff',
                              fontSize: "18px",
                              fontWeight: "blod",
                          },
                      }
                  },
                  plotOptions: {
                      series: {
                          marker: {
                              enabled: false
                          },
                          fillOpacity: 0.3
                      },
                  },
                  tooltip: {
                      pointFormat: '{series.name} :<b>{point.y:,.0f}</b>元'
                  },
                  series: [{
                      name: '茶叶',
                      data: [1500,2260,1900,1700,2000,1200,1600,2000,1300,2300,2600,2500,1560,1750,2260,1900,1700,2000,1200,1600,2000,1300,2300,2600,2500,1560,1750]
                  }, {
                      name: '器具',
                      data: [1300,2300,2600,2500,1560,1750,1500,2260,1900,1700,2000,1200,1600,2000,2300,2600,2500,1560,1750,1500,2260,1900,1700,2000,1200,1600,2000]
                  }, {
                      name: '食品',
                      data: [1300,2300,2260,1900,1700,2000,1200,1600,2000,2600,2500,1560,1750,1500,2300,2260,1900,1700,2000,1200,1600,2000,2600,2500,1560,1750,1500]
                  }, {
                      name: '其他',
                      data: [1300,1200,1600,2000,2600,2500,1560,1750,2300,2260,1900,1700,2000,1500,1200,1600,2000,2600,2500,1560,1750,2300,2260,1900,1700,2000,1500]
                  }]
              }
          }
      },
      created() {
          this.refreshData();
          this.getWt()
      },
      methods:{
          getWt(){
              axios.get('https://www.yiketianqi.com/free/day?appid=64668163&appsecret=AHYuG8Nr&unescape=1').then(res=>{
                  console.log(res.data)
                      this. weather.temperature =res.data.wea + ' ' + res.data.air+'°'
                  this. weather.wind=res.data.win
                      this. weather.date=res.data.date,
                      this. weather.week=res.data.week
                      this. weather.time=res.data.update_time

              })
          },
          refreshData(){
              $bus.on('rightData',(data)=>{
                  console.log(data)
                  //右侧饼图
                  this.storeChartOptions.series.data = [
                      {
                          name: '49%',
                          y: 49,
                      },
                      ['15%',   15.0],
                      ['36%',   36.0]

                  ]
                  HighCharts.chart('store_chart', this.storeChartOptions)
                  //右侧曲线图
                  this.transformOptions.series = [{
                      name: '茶叶',
                      data: [1500,2260,1900,1700,2000,1200,1600,2000]
                  }, {
                      name: '器具',
                      data: [1300,2300,2600,2500,1560,1750,1500,2260]
                  }, {
                      name: '食品',
                      data: [1300,2300,2260,1900,1700,2000,1200,1600]
                  }, {
                      name: '其他',
                      data: [1300,1200,1600,2000,2600,2500,1560,1750]
                  }]
                  HighCharts.chart('transform_chart', this.transformOptions)
                  // 右侧表格
                  this.city_data=[{
                      region:'集美区',
                      joinResidueNum:10,
                      joinUsingNum:2,
                      selfResidueNum:25,
                      selfUsingNum:4,
                  }]
              })
          }
      },
      mounted() {
          HighCharts.chart('store_chart', this.storeChartOptions)
          HighCharts.chart('transform_chart', this.transformOptions)
      },
  }
</script>

<style scoped>
.DataRight{
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 20%;
    color: #fff;
    z-index: 90;
}
.store{
    height: 20%;
    flex: 20%;
}
.teaHouseTransform{
    flex: 30%;
    height: 30%;
}
.city_data{
    flex: 30%;
    height: 30%;
    padding-top: 2%;
}
.right_content{
    //padding: 20px 0;
    width: 95%;
    margin-left: auto;
    margin-right: 5%;
    box-sizing: border-box;
}
.weather{
    flex: 10%;
    padding-bottom: 2%;
    display: flex;
    flex-direction: column-reverse;
}
.weather_box{
    display: flex;
    align-items: center;
    margin-top: auto;
    height: 50px;
    line-height: 51px;
}
.weather_box div{
    margin: 0 2%;
}
.store_info{
    display: flex;
    align-items: center;
    height: 90%;
}
.store_info_left{
    width: 40%;
    padding-left: 10%;
    text-align: left;
}
.store_info_left div{
    padding: 5px 0;
}
.store_info_left p{
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.store_city{
    position: relative;
    color: #288ddd;
    text-align: left;
}
.store_city:before{
    content: '';
    position: absolute;
    left: -20px;
    top: 12px;
    border: 5px solid transparent;
    border-left-color: #fff;
    z-index: 99;
}
.store_city:after{
    content: '';
    position: absolute;
    left: -21px;
    top: 10px;
    border: 7px solid transparent;
    border-left-color: rgba(43, 154, 239,0.4);
}
.store_info_right{
    width: 50%;
    height: 100%;
}
.store_form{
  height: 70%;
}
.store_form .chartSize{
    width: 100%;
    height: 100%;
}
.teaHouseTransform_info{
    height: 80%;
}
.transform_chart{
    padding-top: 2%;
    height: 100%;
}
.chartSize{
    height: 100%;
    width: 100%;
}
.city_data{
    height: 20%;
}
.city{
    padding: 5px 0;
    font-size: 18px;
}
.city_data_info{
    padding-top: 10px;
    padding-left: 8%;
    height: 100%;
}
.table_box{
    margin-top: 10px;
    width: 100%;
    height: 70%;
    overflow: hidden;
    overflow-y: scroll;
}
.table_box::-webkit-scrollbar{width:0;}
.table_box >table{
    border-color: #124367;
    background-image: linear-gradient(-45deg,#1d3150,rgba(7, 11, 27,0.5),#173b71,#121d44,rgba(7, 11, 27,0.5));
}
.table_box table thead{
    position: sticky;
    left: 0;
    top: 0;
    background-color: #173b71;
}
.table_box table thead tr:nth-child(1) td:nth-child(1){
    border-bottom-color: #0291e5;
    border-right-color: #0291e5;
}
.table_box table thead tr:nth-child(2) td{
    border-bottom-color: #0291e5;
}
.table_box table tbody tr td:nth-child(1){
    border-right-color: #0291e5;
}
</style>
