<template>
    <div class="data_left">
<!--城市选择-->
        <div class="region">
            <div class="info">
                <h2 class="txt_bg">{{ city_text }}</h2>
                <p class="txt_bg">{{cnToEn(city_text)}}</p>
            </div>
            <div class="select">
                <p class="input_box" @click="openCity">请选择行政区域</p>
                <p class="search" @click="dialogVisible=true">请输入茶室名称</p>
            </div>
            <div class="point"></div>
        </div>
<!--茶馆排序-->
        <div class="transform content" >
            <div class="title">
                <div class="title_top"></div>
                <div class="title_info">
                    <img src="../../static/right.png">
                    <el-dropdown trigger="click">
                        <span class="el-dropdown-link txt txt_bg">
                          {{ selectTitle }} <i class="title_dropdown"></i>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu   class="dropdown_style">
                                <el-dropdown-item v-for="item in slectList" :key="item" @click="selectData(item.name)" :icon="Plus">{{item.name}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                    <div class="right_button" >
                        <el-button :class="transformBtnActive == 1 ? 'BtnActive' : ''" @click="switchTransform(1)">本周</el-button>
                        <el-button :class="transformBtnActive == 2 ? 'BtnActive' : ''" @click="switchTransform(2)">本月</el-button>
                        <el-button :class="transformBtnActive == 3 ? 'BtnActive' : ''" @click="switchTransform(3)">本季</el-button>
                    </div>
                </div>
                <div class="title_bottom"></div>
            </div>
            <div class="sort_box">
                <div class="items" v-for="(item,index) in transformList" :key="index">
                    <div class="tag">{{ index+1 }}</div>
                    <div class="name" @click="toTeahouse(item)">{{ item.merchant_name }}</div>
                    <div class="schedule">
                        <div :style="'width:'+item.merchant_all_num+'%'">
                            <p></p>
                        </div>
                    </div>
                    <div class="num" style="margin-left: 3%">{{item.merchant_all_num}}</div>
                </div>
            </div>
            <div class="bottom">
                <div class="triangle bottom_left"></div>
                <div class="img">
                    <img src="../../static/box_bottom.png">
                </div>
                <div class="triangle bottom_right"></div>
            </div>
        </div>
<!--异常茶室-->
        <div class="score content">
            <div class="title">
                <div class="title_top"></div>
                <div class="title_info">
                    <img src="../../static/right.png">
                    <div class="txt txt_bg">异常茶馆排序</div>
                    <!--  <div class="right_button" >
                          <div class="text">单位/小时</div>
                      </div>-->
                </div>
                <div class="title_bottom2"></div>
            </div>
            <div class="sort_box score_info">
                <el-scrollbar>
                    <div class="items" v-for="(item,index) in abnormal_list" :key="index">
                        <div class="tag">{{ index+1 }}</div>
                        <div class="name"  @click="toTeahouse(item)">{{ item.merchant_name }}</div>
                        <div class="schedule">
                            <div :class="item.time > 72 ? 'red' :''" :style="'width:'+item.schedule+'%'">
                                <p></p>
                            </div>
                        </div>
                        <div class="num" style="margin-left: 3%">{{item.date}}</div>
                    </div>
                </el-scrollbar>
            </div>
            <div class="bottom">
                <div class="triangle bottom_left"></div>
                <div class="img">
                    <img src="../../static/box_bottom.png">
                </div>
                <div class="triangle bottom_right"></div>
            </div>
        </div>
<!-- 使用率 -->
        <div class="usage content">
            <div class="title">
                <div class="title_top"></div>
                <div class="title_info">
                    <img src="../../static/right.png">
                    <div class="txt txt_bg">当前选中茶室 - {{active}}</div>
                </div>
                <div class="title_bottom3"></div>
            </div>
            <div class="tuglie2">
                <div>单位/次</div>
                <div class="tuglie_right">
                    <div>空席使用数</div>
                    <div>自营使用</div>
                    <div>转化次数</div>
                </div>
            </div>
            <div class="usage_info">
                <div class="usage_form">
                    <div class="pie-pic" id="co_bottom"></div>
                </div>
            </div>
            <div class="bottom">
                <div class="triangle bottom_left"></div>
                <div class="img">
                    <img src="../../static/box_bottom.png">
                </div>
                <div class="triangle bottom_right"></div>
            </div>
        </div>
    </div>
    <div class="mask" v-if="showPop"  @click.stop="showPop=false"></div>
    <div class="popup-draw-active" v-if="showPop">
        <div class="m-4 popup_info">
            <div>
                <p>请选择行政区域</p>
                <el-cascader class="popup_select" placeholder="请选择" :options="options" @change="cityChange" :show-all-levels="false" />
            </div>
            <div class="pop_btn">
                <el-button size="large" @click="showPop = false" type="info" class="btn">取消</el-button>
                <el-button size="large" @click="editCity" type="primary" class="btn">确定</el-button>
            </div>
        </div>
    </div>
    <el-dialog
        v-model="dialogVisible"
        title="输入茶馆名称"
        width="30%"
        :before-close="handleClose"
    >
        <el-select v-model="value" filterable placeholder="茶馆名称" remote:remote-method="remoteMethod" remote reserve-keyword no-data-text="没有匹配的茶馆"
                   :remote-method="remoteMethod" :loading="loading" no-match-text="没有匹配的茶馆" reserve-keywordno-match-text="没有匹配的茶馆">
            <el-option
                v-for="item in searchList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
        </el-select>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirm">确认</el-button>
      </span>
        </template>
    </el-dialog>
</template>
<script>
import HighCharts from 'highcharts'

import highcharts3d from 'highcharts/highcharts-3d'
import $bus from '../utils/bus.js'

import ChineseHelper from '../utils/ChineseHelper.js'

highcharts3d(HighCharts)
export default {
    name: 'DataLeft',
    props: {
        msg: String,
        options:{
            type:Array,
            default:function (){
                return [];
            }
        },
        abnormalList:{
            type:Array,
            default:function (){
                return [];
            }
        },
        storereTurn:{
            type:Array,
            default:function (){
                return [];
            }
        },
        city: String
    },
    watch:{
        city(){
            this.city_text = this.city
        },
        abnormalList(){
            this.abnormal_list = this.abnormalList
            this.abnormal()
        },
        storereTurn(){
            this.transformList = this.storereTurn
            this.abnormal()
        }
    },
    data() {
        return {
            abnormal_list:this.abnormalList,
            city_text: this.city,
            value:'',
            searchList: [],//联想搜索
            states:[
                '八马茶业',
                '北米茶业',
                '陈升红',
                '初心院',
                '八马茶业',
                '八马茶业',
                '八马茶业',
            ],
            list:'',
            loading:false,
            showPop:false,
            dialogVisible:false,//搜索弹窗
            id:'test',
            chart:null,
            activeCity:'',//选中区域
            city_bottom: 'XIAMEN',
            transformBtnActive:1,
            transformList: this.storereTurn,
        // {
        //     id: 1,
        //         name: "南化七号Ticoffee X 而作之茶",
        //     schedule: 80,
        //     merchant_longitude:"24.513744354248",
        //     merchant_latitude:"117.65576171875",
        // },
            active: "无",
            score: 98,
            selectTitle:'茶馆转化率排序',
            slectList:[{
                name:'茶馆转化率排序',
                id:0,
            },{
                name:'空席使用率',
                id:1,
            }],
            props1 : {
                checkStrictly:true,
            },
            chartOptions: {
                title:'',
                colors: ['rgba(4, 68, 182,0.6)', 'rgba(251, 158, 66,0.6)', 'rgba(220, 220, 220,0.6)'],
                chart: {
                    type: 'pie',
                    options3d: {
                        enabled: true,
                        alpha: 60
                    },
                    style: {
                        color: '#ffffff',
                        fontSize: "18px",
                        fontWeight: "blod",
                        fontFamily: ""
                    },
                    backgroundColor: {
                        linearGradient: [0, 0, 500, 500],
                        stops: [
                            [0, 'rgb(255, 255, 255,0)'],
                            [0, 'rgb(200, 200, 255,0)']
                        ]
                    },
                },
                tooltip: {
                    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
                },
                plotOptions: {
                    pie: {
                        innerSize: 50,
                        depth: 35,

                    }
                },
                credits: {
                    enabled:false,                    // 默认值，如果想去掉版权信息，设置为false即可
                },
                series: [
                    {
                        type: "pie",
                        name: "",
                        data: [
                            ["49.0", 49.0],
                            ["36.0", 36.0],
                            ["15.0", 15.0],
                        ],
                    },
                ]
            },
            teahouseOption: {
                title:'',
                colors: ['rgba(29, 128, 218,0.7)', 'rgba(2, 244, 255,0.7)', 'rgba(184, 139, 11,0.7)'],
                chart: {
                    type: 'column',
                    backgroundColor: {
                        linearGradient: [0, 0, 500, 500],

                        stops: [
                            [0, 'rgb(255, 255, 255,0)'],
                            [0, 'rgb(200, 200, 255,0)']
                        ]
                    },
                    style: {
                        color: '#ffffff',
                        fontSize: "18px",
                        fontWeight: "blod",
                        fontFamily: "",
                        borderWidth: 0,
                    },
                },
                xAxis: {
                    categories: ['1', '2', '3', '4','5','6','7','8'],
                    labels: {
                        align: 'right',
                        x: 1,
                        y: 20,
                        style: {
                            color: '#ffffff',
                            fontSize: "18px",
                            fontWeight: "blod",
                        },
                    },

                },
                credits: {
                    enabled:false,                    // 默认值，如果想去掉版权信息，设置为false即可
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ''
                    },
                    labels: {
                        style: {
                            color: '#ffffff',
                            fontSize: "18px",
                            fontWeight: "blod",
                        },
                    },
                    stackLabels: {  // 堆叠数据标签
                        enabled: true,
                        style: {
                            fontWeight: 'bold',
                            color: (HighCharts.theme && HighCharts.theme.textColor) || 'gray'
                        }
                    },

                },
                legend: {
                    enabled:false,
                    align: 'right',
                    x: -30,
                    verticalAlign: 'bottom',
                    y: 30,
                    floating: true,
                    backgroundColor: (HighCharts.theme && HighCharts.theme.background2) || 'white',
                    borderColor: '#CCC',
                    borderWidth: 1,
                    shadow: false
                },
                tooltip: {
                    formatter: function () {
                        return '<b>' + this.x + '</b><br/>' +
                            this.series.name + ': ' + this.y + '<br/>' +
                            '总量: ' + this.point.stackTotal;
                    }
                },
                plotOptions: {
                    column: {
                        stacking: 'normal'
                    },
                    series: {
                        borderWidth: 0,
                    },
                },
                series: [{
                    name: '空席使用数',
                    data: [8, 3, 4, 7, 2,3,7,8]
                }, {
                    name: '自营使用',
                    data: [11, 2, 3, 2, 1,6,4,3]
                }, {
                    name: '转化次数',
                    data: [7, 4, 4, 2, 5,2,2,2]
                }],
                style: {
                    border:'none'
                },

            }
        }
    },
    created() {
        $bus.on('mapClick',(data)=>{
            console.log(data);
            this.active = data.merchant_company_name
        })
        console.log(this.options)

        this.list = this.states.map((item) => {
            return { value: `${item}`, label: `${item}` }
        })
        // this.$on('mapClick',e =>{
        //     console.log(e)
        // })
    },
    methods: {
        openCity(){
           this.showPop=true

        },
        abnormal() {
            for (const i in this.abnormal_list){
                let time = parseInt(this.abnormal_list[i].day_time/3600)
                let day = time / 24;
                let date = parseInt(day) + '天' +  (time %24) + 'h'
                this.abnormal_list[i].date = date
                if(time<72){
                  let schedule =  (time - 48 ) / 24 * 100
                  this.abnormal_list[i].schedule = schedule
                }else{
                    this.abnormal_list[i].schedule = 100
                }
            }
        },
        // 搜索茶馆
        remoteMethod(query){
            if (query) {
                this.loading = true
                setTimeout(() => {
                    this.loading = false
                    this.searchList = this.list.filter((item) => {
                        return item.label.toLowerCase().includes(query.toLowerCase())
                    })
                }, 200)
            } else {
                this.searchList = []
            }
        },
        //滑动茶馆定位位置
        toTeahouse(info){
            console.log(info)
            let data = {
                merchant_longitude:info.merchant_longitude,
                merchant_latitude:info.merchant_latitude,
            }
            $bus.emit('teahousePosition',data)
            this.active = info.name
        },
        switchTransform(e){
            this.transformBtnActive = e
            this.$emit('timeType',e)
        },
        editCity(){
            let  data = ''
            this.city_text = this.activeCity
            this.$emit('selectCity',this.activeCity)
            $bus.emit('rightData',data)
            $bus.emit('mapCity',data)
            this.showPop = false
        },
        // 排序一的下拉选择
        selectData(e){
            this.selectTitle = e
            this.$emit('leftOne',e)
        },
        cityChange(e){
            console.log(e)
            this.activeCity = e[e.length - 1]
        },
        confirm(){
            if(this.value != ''){
                this.dialogVisible = false
                this.active = this.value
                let data = {
                    merchant_longitude:"24.5143",
                    merchant_latitude:"117.66776",
                }
                $bus.emit('teahousePosition',data)
                this.value = ''
            }else{
                alert('请先输入茶馆名称')
            }
        },
        cnToEn(e){
            return  ChineseHelper.ConvertPinyin(e)
        }
    },
    mounted() {
        // HighCharts.chart(this.id, this.chartOptions);
        HighCharts.chart('co_bottom', this.teahouseOption);
    },
};
</script>
<style>

.data_left {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 20%;
    z-index: 92;
    display: flex;
    flex-direction: column;
}
.transform{
    flex: 25%;
    height: 25%;
}
.region{
    flex: 10%;
    height: 10%;
    position: relative;
    display: flex;
    padding-top:5%;
    padding-left: 10%;
    align-items: center;
}
.usage{
    flex: 35%;
    height: 35%;
}
.info h2{
    font-weight: 600;
    font-size: 34px;
    margin:0;
}
.info p{
    font-weight: 600;
    font-size: 20px;
    margin: 5px 0;
}
.point{
    position: absolute;
    left: 0;
    top: 0;
    background-color:#2783ca;
    width: 10px;
    height: 10px;
}
.select{
    margin-left: 5%;
    width: 50%;
    height: 50%;
    padding-top: 10%;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}
.select .input_box{
    margin: 0;
    display: inline-block;
    background-color: #0c1e37;
    border-radius: 5px;
    border: 1px solid #1d3c72;
    padding: 1% 10%;
    color: #9da4af;
    cursor:default
}

.select .search{
    margin: 10% 0;
    display: inline-block;
    background-color: #0c1e37;
    border-radius: 5px;
    border: 1px solid #1d3c72;
    padding: 1% 10%;
    color: #9da4af;
    cursor:default
}

.content{
    padding: 20px 0;
    width: 95%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.score{
    flex: 20%;
    height: 20%;
}
.sort_box{
    height: 100%;
}

.sort_box .items{
    display: flex;
    padding: 15px;
    align-items: center;
    color: #fff;
    font-size: 14px;
}

.sort_box .items .tag{
    position: relative;
    display: inline-block;
    color: #fff;
    padding: 0 5px;
    border-radius: 2px;
    font-size: 12px;
    background-color: #ff3232;
    margin-right: 3%;
}

.sort_box .items .tag:before{
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -3px;
    margin-right: -6px;
    //transform: rotate(45deg);
    border: 3px solid transparent;
    border-left-color: #ff3232;
}

.sort_box .items:nth-child(5n+2) .tag{
    background-color: #ff9800;
}
.sort_box .items:nth-child(5n+2) .tag:before{
    border-left-color: #ff9800;
}

.sort_box .items:nth-child(5n+3) .tag{
    background-color: #ffd200;
}
.sort_box .items:nth-child(5n+3) .tag:before{
    border-left-color: #ffd200;
}
.sort_box .items:nth-child(5n+4) .tag{
    background-color: #3d8fff;
}
.sort_box .items:nth-child(5n+4) .tag:before{
    border-left-color: #3d8fff;
}
.sort_box .items:nth-child(5n+5) .tag{
    background-color: #3d8fff;
}
.sort_box .items:nth-child(5n+5) .tag:before{
    border-left-color: #3d8fff;
}

.sort_box .items .name{
    width: 35%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    user-select: none;
}
.sort_box .items .schedule{
    margin-left: 2%;
    width: 40%;
    background-color: #102449;
    border-radius: 6px;
    height: 12px;
}
.sort_box .items .schedule div{
    height: 100%;
    border-radius: 6px;
    background-image: linear-gradient(to right ,#0c1e3b,#1bc7df);
}
.sort_box .items .schedule .red{
    height: 100%;
    border-radius: 6px;
    background-image: linear-gradient(to right ,#ceb6b6,#e33a3d);
}
.sort_box .items .schedule div p{
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background-color: #fff;
    float: right;
    margin: -1px -1px 0 0;
}


.score_info{
    position: relative;
    height: 80%;
}
.score_info .score{
    position: relative;
    width: 50%;
    padding: 10% 0;
    text-align: center;
}
.score_info .score .img .score_txt{
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #3be0e8;
    font-size: 50px;
    text-shadow:0 0 6px rgba(59, 224, 232,0.4);
}
.score_info .score .img{
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
}
.score_right{
    width: 50%;
    height: 100%;
}
.score_form{
    height: 220px;
}
.pie-pic{
    width: 100%;
    height: 100%;
}
.highcharts-background{
    background: transparent !important;
}
.usage_info{
    height: 70%;
}
.usage_info .usage_form{
    height: 100%;
}
.tuglie2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color:#ffffff;
    padding: 1% 0;
}
.tuglie2 .tuglie_right{
    display: flex;
}

.tuglie2 .tuglie_right div:before{
    content: '';
    display: inline-block;
    margin: 0 5px;
    width: 15px;
    height: 10px;
    border-radius: 8px;
    background-color: #fff;
}
.tuglie2 .tuglie_right div:nth-child(1):before{
    background-color: #1d80da;
}
.tuglie2 .tuglie_right div:nth-child(2):before{
    background-color: #02f4ff;
}
.tuglie2 .tuglie_right div:nth-child(3):before{
    background-color: #b88b0b;
}

.title_dropdown:after{
    content: '';
    position: absolute;
    right: -22px;
    top: 50%;
    margin-top: -7px;
    border: 7px solid transparent;
    border-top-color: rgba(43, 154, 239,0.4);
}
.title_dropdown:before{
    content: '';
    position: absolute;
    right: -20px;
    top: 50%;
    margin-top: -5px;
    border: 5px solid transparent;
    border-top-color: #fff;
    z-index: 99;
}
.el-popper{
    border-radius: 0;
    border-color: #2e90ff;
}
.el-popper.is-light{
    border-color: #2e90ff;
}
.dropdown_style {
    background-color: rgba(26, 58, 116, 1);
    border-radius: 0;
    border: none;


    .el-dropdown-menu__item{
        color: #fff !important;
        padding: 5px 40px;
    }
    .el-dropdown-menu__item:hover{
        color: #fff !important;
        background-color: #0b3ba2;
    }
}
.mask{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 990;
    width: 100%;
    height: 100%;
    background-color:rgba(0,0,0,0.7);
}
.popup-draw-active{
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 999;
    width: 640px;
    height: 300px;
    padding: 30px;
    border-radius: 10px;
    border: 2px solid #2e90ff;
    background-color: rgba(15,106,180,0.75);
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    transition: .3s;
    line-height: 1;
    text-align: center;
    color: #fff;
    background-repeat: round;
    background-size: 100%;
}
.popup_info{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.pop_btn{
    box-sizing: border-box;
    padding: 0 20%;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.popup_info .btn{
    width: 40%;
}

.popup_select{
    margin-top: 15%;
}
</style>